import React from 'react';
import { Navbar } from 'react-bootstrap';


class AppNavbar extends React.Component {

  render() {

    return (
      <div>
        <Navbar bg="dark" variant="dark" fixed="top">
          <Navbar.Brand href="/">
            Home
          </Navbar.Brand>
        </Navbar>
      </div>
    );
  }
}

export default AppNavbar;
