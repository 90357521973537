import React from 'react';

import AppNavbar from './AppNavbar'
import pusheen_tippy_taps from './images/pusheen_tippy_taps.gif'

import './App.css';

function App() {
  return (
    <div className="App">
      <AppNavbar />
      <header className="App-header">
        <img className="unselectable" alt="dancing" src={pusheen_tippy_taps} />
        <p>
          Meow Meow Meow
        </p>
      </header>
    </div>
  );
}

export default App;
